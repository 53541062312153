import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(
      _ctx.show
        ? 'badge rounded-pill border text-danger border-danger mx-1'
        : 'badge rounded-pill border border-dark bg-grayish text-dark mx-1'
    ),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change-state'))),
    role: "button"
  }, " Show " + _toDisplayString(_ctx.thingToShow), 3))
}