
import { PropType, defineComponent } from "vue";
import dayjs from "dayjs";
import EventService from "@/services/EventService";
import { Event } from "@/types";
import UserService from "@/services/UserService";
import refreshMixin from "@/mixins/refreshMixin";
import ShowHideButton from "../Utility/ShowHideButton.vue";
import { showAttendeesModal } from "../Modals/ModalController";
export default defineComponent({
  mixins: [refreshMixin],
  async created() {
    await this.getUserEvents();
    this.loadMarkers();
  },
  computed: {
    showCanceled: {
      get() {
        return this.$store.state.showCanceled;
      },
      async set(sc) {
        this.$store.commit("setShowCanceled", sc);
        await this.getUserEvents();
        this.loadMarkers();
      },
    },
    showNotAttending: {
      get() {
        return this.$store.state.showNotAttending;
      },
      async set(na) {
        this.$store.commit("setShowNotAttending", na);
        await this.getUserEvents();
        this.loadMarkers();
      },
    },
  },
  methods: {
    getTooltipClass(tooltip) {
      const c = ["text-wrap"];
      if (tooltip.cancelled) {
        c.push("text-decoration-line-through,  text-danger");
      } else if (tooltip.attendingStatus === -1) {
        c.push("text-dark");
      } else if (tooltip.isPast) {
        c.push("text-muted");
      } else if (tooltip.text.includes("POLL ENDS")) {
        c.push("text-primary");
      } else if (tooltip.text.includes("EVENT")) {
        c.push("text-success");
      }
      return c;
    },
    loadMarkers() {
      this.markers = [];
      const daysThatEventsHappen = this.events.map((e) => ({
        day: dayjs(e?.eventStartDateTime).format("YYYY-MM-DD"),
        eventId: e?.eventId,
        name: e?.eventName,
        eventStartDateTime: e?.eventStartDateTime,
        teamName: e?.teamName,
        cancelled: e?.cancelled,
        attendingStatus: e?.attendingStatus,
      }));
      const daysThatVotesEnd = this.events.map((e) => ({
        day: dayjs(e?.restaurantVoteEndDateTime).format("YYYY-MM-DD"),
        eventId: e?.eventId,
        name: e?.eventName,
        eventStartDateTime: e?.eventStartDateTime,
        teamName: e?.teamName,
        cancelled: e?.cancelled,
        attendingStatus: e?.attendingStatus,
      }));
      const dayTracker = [];
      for (const d of daysThatVotesEnd) {
        const color = this.getEventColor(
          d.cancelled,
          d.eventStartDateTime,
          "vote",
          d.attendingStatus
        );
        if (dayTracker.includes(d.day)) {
          const m = this.markers.findIndex((m) => m.date === d.day);
          this.markers[m].tooltip.push({
            text: "POLL ENDS: " + d.name + " with " + d.teamName,
            color: color,
            type: "dot",
            eventId: d.eventId,
            attendingStatus: d.attendingStatus,
            cancelled: d.cancelled,
            isPast: dayjs().isAfter(dayjs(d.eventStartDateTime)),
          });
          this.markers[m].color = color;
        } else {
          dayTracker.push(d.day);
          this.markers.push({
            date: d.day,
            type: "line",
            color: color,
            tooltip: [
              {
                text: "POLL ENDS: " + d.name + " with " + d.teamName,
                color: color,
                type: "dot",
                attendingStatus: d.attendingStatus,
                isPast: dayjs().isAfter(dayjs(d.eventStartDateTime)),
                eventId: d.eventId,
                cancelled: d.cancelled,
              },
            ],
          });
        }
      }

      for (const d of daysThatEventsHappen) {
        const color = this.getEventColor(
          d.cancelled,
          d.eventStartDateTime,
          "event",
          d.attendingStatus
        );

        if (dayTracker.includes(d.day)) {
          const m = this.markers.findIndex((m) => m.date === d.day);
          this.markers[m].tooltip.push({
            text: "EVENT: " + d.name + " with " + d.teamName,
            color: color,
            attendingStatus: d.attendingStatus,
            type: "dot",
            isPast: dayjs().isAfter(dayjs(d.eventStartDateTime)),
            eventId: d.eventId,
            cancelled: d.cancelled,
          });
          this.markers[m].color = color;
        } else {
          dayTracker.push(d.day);
          this.markers.push({
            date: d.day,
            type: "line",
            color: color,
            tooltip: [
              {
                text: "EVENT: " + d.name + " with " + d.teamName,
                eventId: d.eventId,
                type: "dot",
                attendingStatus: d.attendingStatus,
                color: color,
                isPast: dayjs().isAfter(dayjs(d.eventStartDateTime)),
                cancelled: d.cancelled,
              },
            ],
          });
        }
      }
    },
    async refresh() {
      try {
        await this.getUserEvents();
        this.loadMarkers();
      } catch (error) {
        if (error) console.error(error);
      } finally {
        this.completeRefresh();
      }
    },
    getEventColor(cancelled, eventStartDateTime, type, status) {
      if (cancelled) {
        return "red";
      } else if (status === -1) {
        return "black";
      } else if (dayjs().isAfter(dayjs(eventStartDateTime))) {
        return "gray";
      } else if (type === "vote") {
        return "#00bfe4";
      } else return "green";
    },
    async getUserEvents() {
      try {
        this.eventsLoaded = false;
        this.events = [];
        const response = await UserService.getUserEvents(
          this.$store.state.userFirebaseUid,
          true,
          this.showCanceled,
          this.showNotAttending
        );
        this.events = response.data.eventsAndTeamNames;
      } catch (e) {
        console.log(e);
      } finally {
        this.eventsLoaded = true;
      }
    },
  },
  data() {
    return { markers: [], events: [] as Event[] };
  },
  components: { ShowHideButton },
});
