import { mapState } from "vuex";
import store from "@/store/store";
import eventBus from "../components/EventBus";
export default {
  props: {
    shouldRefresh: false,
  },
  emits: ["refresh-complete", "set-refreshable"],
  computed: {
    ...mapState({
      shouldRefreshComponentData: (state) => state.shouldRefreshComponentData,
    }),
  },
  methods: {
    async refresh(): Promise<void> {
      // This method should be overridden in the component that uses the mixin
      throw new Error("You have to implement the method 'refresh'!");
    },
    completeRefresh(): void {
      this.$emit("refresh-complete");
    },
  },
  watch: {
    shouldRefresh(newVal: boolean): void {
      if (newVal) {
        this.refresh();
      }
    },
    shouldRefreshComponentData(newVal: boolean): void {
      if (newVal) {
        try {
          this.refresh();
        } finally {
          store.commit("setShouldRefreshComponentData", false);
        }
      }
    },
  },
  async mounted(): Promise<void> {
    await this.$emit("set-refreshable", true);
    eventBus.$on("requires-refresh", async () => {
      await this.refresh();
    });
  },
  async beforeUnmount(): Promise<void> {
    await this.$emit("set-refreshable", false);
    eventBus.$off("requires-refresh");
  },
};
